

<div class="container text-center">

  <div>
      <button class="btn btn-sm btn-dark" (click)="onChangeBootwatch('slate')" >
        &nbsp; &nbsp; &nbsp; &nbsp;
      </button>
      <button class="btn btn-sm btn-light" (click)="onChangeBootwatch('yeti')" >
        &nbsp; &nbsp; &nbsp; &nbsp;
      </button>
  </div>

  <div class="jumbotron" 
       style="margin-top: 15px; border-radius: 20px; " >
      <h1>pvWay</h1>
      <img src="../assets/logo/pvwayLogoTextLess256.png">
      <h2>paving Your way</h2>
      <h3>since the 80's</h3>
  </div>

  <div>
    
      <h2>Entry business solutions</h2>
      <h3>If you don't need more, don't pay for more</h3>
      
      <div parallax [config]="{initialValue: -100, ratio: .7}"
           class="parallax-entry-sol" >
          <carousel [isAnimated]="true" [noPause]="false"  >
            
            <slide>
              <img src="../assets/screenShots/iota01.png" 
                   class="sol-screen-shot" >
              <div class="carousel-caption text-center" >
                  <div class="sol-slide-caption" >
                    <h3>iOta</h3>
                    <h4>Handy management tools</h4>
                    <h5>For home and small businesses</h5>
                    <button class="btn btn-sm btn-success"
                            (click)="onAccessIota()"
                    >Sign Up Now</button>
                  </div>
              </div>                   
            </slide>

            <slide>
                <img src="../assets/screenShots/iota02.png" 
                     class="sol-screen-shot" >
                <div class="carousel-caption text-center" >
                  <div class="sol-slide-caption" >
                      <h3>iOta Home Pack</h3>
                      <h4>Consolidated Payment Calendar</h4>
                      <h5>Never miss a payment anymore</h5>
                    <button class="btn btn-sm btn-success"
                            (click)="onAccessIota()"
                    >Sign Up Now</button>
                  </div>
                </div>                   
            </slide>

            <slide>
                <img src="../assets/screenShots/iota03.png" 
                     class="sol-screen-shot" >
                <div class="carousel-caption text-center" >
                  <div class="sol-slide-caption" >
                      <h3>iOta Home Pack</h3>
                      <h4>Financial dasboard</h4>
                      <h5>Understand your expense flow</h5>
                    <button class="btn btn-sm btn-success"
                            (click)="onAccessIota()"
                    >Sign Up Now</button>
                  </div>
                </div>                   
            </slide>

            <slide>
              <img src="../assets/screenShots/iota04.png" 
                   class="sol-screen-shot" >
              <div class="carousel-caption text-center" >
                <div class="sol-slide-caption" >
                    <h3>iOta Doc Pack</h3>
                    <h4>Index both your private and pro documents</h4>
                    <h5>Search by key words</h5>
                    <button class="btn btn-sm btn-success"
                            (click)="onAccessIota()"
                    >Sign Up Now</button>
                </div>
              </div>                   
          </slide>

          <slide>
            <img src="../assets/screenShots/iota05.png" 
                 class="sol-screen-shot" >
            <div class="carousel-caption text-center" >
              <div class="sol-slide-caption" >
                  <h3>iOta Bus Pack</h3>
                  <h4>Full accounting system</h4>
                  <h5>Get help from the VAT wizzard</h5>
                    <button class="btn btn-sm btn-success"
                            (click)="onAccessIota()"
                    >Sign Up Now</button>
              </div>
            </div>                   
          </slide>
      
          <slide>
            <img src="../assets/screenShots/iota06.png" 
                 class="sol-screen-shot" >
            <div class="carousel-caption text-center" >
              <div class="sol-slide-caption" >
                  <h3>iOta Bus Pack</h3>
                  <h4>Complete set of accounting reports</h4>
                  <h5>including VAT declaration</h5>
                    <button class="btn btn-sm btn-success"
                            (click)="onAccessIota()"
                    >Sign Up Now</button>
              </div>
            </div>                   
          </slide>

          <slide>
            <img src="../assets/screenShots/iota07.png" 
                 class="sol-screen-shot" >
            <div class="carousel-caption text-center" >
              <div class="sol-slide-caption" >
                  <h3>iOta Bus Pack</h3>
                  <h4>Customize your sales docs</h4>
                  <h5>As simple as editing a word document</h5>
                    <button class="btn btn-sm btn-success"
                            (click)="onAccessIota()"
                    >Sign Up Now</button>
              </div>
            </div>                   
          </slide>

          <slide>
            <img src="../assets/screenShots/iota08.png" 
                 class="sol-screen-shot" >
            <div class="carousel-caption text-center" >
              <div class="sol-slide-caption" >
                  <h3>iOta Team</h3>
                  <h4>Unlimited number of users</h4>
                  <h5>Share your docs with your fiscal expert</h5>
                    <button class="btn btn-sm btn-success"
                            (click)="onAccessIota()"
                    >Sign Up Now</button>
              </div>
            </div>                   
          </slide>

        </carousel>
      </div>

      <!-- <h2>Free nuGets factory</h2>
      <h3>Enjoy small nuGets that gonna change your developer's live</h3>
      
      <div parallax [config]="{initialValue: -100, ratio: .1}"  
           class="parallax-nu-gets" >

        <carousel [noPause]="false" [isAnimated]="true" >
          <slide>
            <iframe class="bg-light text-dark read-me" 
            width="70%" height="260px" src="../assets/nugets/msSqlBackupdotNetCore.readme.html" ></iframe>
            <div class="carousel-caption d-none d-md-block">
            </div>
          </slide>
          <slide>
            <iframe class="bg-light text-dark read-me" 
            width="70%" height="260px" src="../assets/nugets/ipApi.readme.html" ></iframe>
            <div class="carousel-caption d-none d-md-block">
            </div>
          </slide>
        </carousel>            

      </div> -->

      <h2>Immerged in dev since the 80's</h2>
      <h3>we keep loving this job day after day</h3>
      <div parallax [config]="{initialValue: -100, ratio: .1}"
           class="parallax-excellence text-light">
        <carousel [isAnimated]="true" >
          <slide >
            <div class="excellence-slide">
                <h3>Currious by design</h3>
              </div>
          </slide>
          <slide>
              <div class="excellence-slide">
                  <h3>Passionate by nature</h3>
              </div>
          </slide>
          <slide>
              <div class="excellence-slide">
                  <h3>Exigent by passion</h3>
              </div>
          </slide>
        </carousel>
      </div>

      <h2>Contact</h2>
      <div parallax [config]="{initialValue: -100, ratio: .2}"
           class="parallax-contact row text-light">
          <div class="col-md-4 d-none d-md-block">
          </div>
          <div class="col-md-4">
              <h3>pvWay ltd</h3>
              <h5>av. Emile Verhaeren, 7</h5>
              <h5>B1300 Wavre</h5>
              <h5>Belgium</h5>
              <h6>info@pvway.com</h6>
          </div>
          <div class="col-md-4 d-none d-md-block">
          </div>          
      </div>
  </div>
  <div style="height: 150px;"></div>
  <div>&copy;pvWay ltd</div>

</div>