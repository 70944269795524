import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pvWay';

  private readonly defaultTheme = 'slate';
  currentTheme: string;

  ngOnInit(): void {

    let localTheme = localStorage.getItem('theme');
    if (!localTheme) {
      localTheme = this.defaultTheme;
    }
    this.createSheet(localTheme, true);
  }

  private createSheet(themeName: string, isActive?: boolean) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.id = themeName;
    link.href = `assets/bootswatch/dist/${themeName}/bootstrap.min.css`;
    link.title = themeName;
    link.disabled = !isActive;
    if (isActive) {
      this.currentTheme = themeName;
    }
    document.head.appendChild(link);
  }

  setBootswatch(newTheme: string) {

    localStorage.setItem('theme', newTheme);
    location.reload();

    // const curSheet = document.getElementById(this.currentTheme) as HTMLLinkElement;
    // if (curSheet){
    //    document.head.removeChild(curSheet);
    // }

    // this.createSheet(newTheme, true);
  }

  onChangeBootwatch(name: string) {
    this.setBootswatch(name);
  }

  onAccessIota(): void {
    window.open('https://iotaSolution.be', '_blank');
  }

}
